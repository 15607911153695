export const INDUSTRIES = {
  accommodation_services: 'Accommodation Services',
  administrative_and_support_services: 'Administrative and Support Services',
  construction: 'Construction',
  consumer_services: 'Consumer Services',
  education: 'Education',
  entertainment_providers: 'Entertainment Providers',
  farming_ranching_forestry: 'Farming, Ranching, Forestry',
  financial_services: 'Financial Services',
  government_administration: 'Government Administration',
  holding_companies: 'Holding Companies',
  hospitals_and_health_care: 'Hospitals and Health Care',
  manufacturing: 'Manufacturing',
  oil_gas_and_mining: 'Oil, Gas, and Mining',
  professional_services: 'Professional Services',
  real_estate_and_equipment_rental_services:
    'Real Estate and Equipment Rental Services',
  retail: 'Retail',
  technology_information_and_media: 'Technology, Information and Media',
  transportation_logistics_supply_chain_and_storage:
    'Transportation, Logistics, Supply Chain and Storage',
  utilities: 'Utilities',
  wholesale: 'Wholesale',
};
