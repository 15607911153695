import React, {useEffect, useState} from 'react';
import JobItem from './job-item';
import {Typography} from 'antd';
import icon from 'assets/images/u35.png';
import {getLocation} from '../../utils/get-location';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

const JobList = ({items}) => {
  const [activeId, setActiveId] = useState(null);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const {id} = useParams();

  useEffect(() => {
    if (id) {
      setActiveId(id);
    }
  }, [id]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (items?.length) {
      navigate(`/${items[0].id}?${params.toString()}`);
    }
  }, [items]);

  if (items.length) {
    return (
      <ul className='flex flex-col'>
        {items.map((el) => (
          <JobItem
            isActive={el.id === activeId}
            key={el.id}
            icon={el.client?.logo || icon}
            id={el.id}
            title={el.job_title}
            workModel={el.job_worksite}
            company={el.client?.name}
            location={getLocation(el)}
          />
        ))}
      </ul>
    );
  }
  return (
    <div className='p-5'>
      <Typography.Text>Job List is Empty</Typography.Text>
    </div>
  );
};

export default JobList;
