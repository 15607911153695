import {useQuery} from 'react-query';
import {instance} from '../api/axios';

export const useGetJobs = ({page}) => {
  return useQuery(['jobs', page], async () => {
    const res = await instance.get('/jobs', {
      params: {
        page,
        limit: 10,
        expand: ['client'],
        include: ['total_count'],
        sort: ['-created_at'],
      },
    });
    return res.data;
  });
};

export const useSearchJobs = ({page, q_text, q_location}) => {
  return useQuery(
    ['search-jobs', page, q_text, q_location],
    async () => {
      const res = await instance.get('/jobs', {
        params: {
          page,
          q_text,
          q_location,
          limit: 10,
          expand: ['client'],
          include: ['total_count'],
          sort: ['-created_at'],
        },
      });
      return res.data;
    },
    {enabled: !!q_text || !!q_location, keepPreviousData: true},
  );
};
