import React from 'react';
import twitter from 'assets/images/twitter.png';
import fb from 'assets/images/facebook.png';
import ld from 'assets/images/linkedin.png';
import cn from 'clsx';

const Navigation = ({classes}) => {
  return (
    <div className={cn('flex flex-col items-center p-4', classes?.root)}>
      <nav className='flex items-center gap-5 mb-5'>
        <a
          href='https://sourcer.com/legal/job-board-terms-conditions'
          target='_blank'
          className='text-gray-300 text-sm'>
          Terms
        </a>
        <a
          href='https://sourcer.com/legal/privacy-policy'
          target='_blank'
          className='text-gray-300 text-sm'>
          Privacy policy
        </a>
        <a
          href='https://sourcer.com/legal/cookie-policy'
          target='_blank'
          className='text-gray-300 text-sm'>
          Cookie policy
        </a>
        <a
          href='https://sourcer.com/contact'
          target='_blank'
          className='text-gray-300 text-sm'>
          Contact us
        </a>
      </nav>
      <nav className='flex items-center gap-5 mb-5'>
        <a href='https://twitter.com/sourcer_inc' target='_blank'>
          <img src={twitter} className='w-6 h-6' alt='twitter' />
        </a>
        <a href='https://www.linkedin.com/company/sourcer-inc/' target='_blank'>
          <img src={ld} className='w-6 h-6' alt='linkedin' />
        </a>
        <a href='https://www.facebook.com/sourcer.inc/' target='_blank'>
          <img src={fb} className='w-6 h-6' alt='facebook' />
        </a>
      </nav>
      <span className='text-gray-300 text-sm'>© 2024 Sourcer, Inc.</span>
    </div>
  );
};

export default Navigation;
