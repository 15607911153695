import React from 'react';
import {Button, message, Upload} from 'antd';
import {Controller, useFormContext} from 'react-hook-form';
import Label from '../additional/label';

const UploadFile = ({isRequired, label, children}) => {
  const {control} = useFormContext();
  const beforeUpload = (file) => {
    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ];
    const isAllowedType = allowedTypes.includes(file.type);
    if (!isAllowedType) {
      message.error('You can only upload PDF and DOCX files!');
      return false;
    }

    const maxFileSize = 1024 * 1024 * 2;
    const isUnderLimit = file.size > maxFileSize;
    if (isUnderLimit) {
      message.error('File must be smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }
    return isAllowedType && isUnderLimit;
  };

  return (
    <Controller
      name='file'
      control={control}
      defaultValue={null}
      render={({field, fieldState: {error}}) => {
        console.log(field, '-----field');
        return (
          <div className='relative pb-5'>
            {!!label && (
              <Label className='mb-2' isRequired={isRequired} text={label} />
            )}
            <Upload
              {...field}
              ref={field.ref}
              accept='.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              onChange={(file) => {
                if (file?.fileList.length) {
                  field.onChange(file);
                } else {
                  field.onChange(null);
                }
              }}
              beforeUpload={(file) => {
                if (beforeUpload(file)) {
                  field.onChange(file); // Store the file in the form's state
                } else {
                  console.log('NULL');
                  field.onChange(null);
                  return false;
                }
                // Prevent default upload behavior
              }}
              fileList={field.value?.fileList || []}
              maxCount={1}>
              <Button type={'primary'}>{children}</Button>
            </Upload>
            {!!error && (
              <span className='absolute left-0 bottom-0 text-error text-xs'>
                {error.message}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default UploadFile;
