import {object, string} from 'yup';
import {getRequiredMessage} from 'utils/message-helper';
import {PHONE} from 'utils/regx';

export const ContactInfoSchema = object({
  first_name: string().required(getRequiredMessage('First Name')),
  last_name: string().required(getRequiredMessage('Last Name')),
  title: string(),
  email: string()
    .email('Enter correct email address')
    .required(getRequiredMessage('Email')),
  phone_number: string().matches(PHONE, 'Enter correct phone'),
});

export const AccountOwner = ContactInfoSchema.shape({
  email_address: string().nullable().notRequired(),
  email: string()
    .email('Enter correct email address')
    .required(getRequiredMessage('Email')),
});
